<template>
  <TextField
    v-model="searchText"
    :placeholder="placeholder"
    :clearable="false"
    icon-disabled
    size="small"
    class="mm-search-text-field"
    :class="{ text: !!searchText }"
    :disabled="disabled"
    @enter="onClickSearch"
  >
    <template #afterInput>
      <ClientOnly>
        <div
          class="mm-search-text-field_after__container"
          :class="{ 'mm-search-text-field_after__container--disabled': disabled }"
        >
          <SvgIcon
            v-if="searchText"
            :src="EIconPath.NavigationClose24PxSvg"
            class="icon mm-search-text-field__clear__icon"
            @click="onClickClear"
          />
          <SvgIcon
            :src="EIconPath.NavigationSearchSvg"
            class="icon"
            @click="onClickSearch"
          />
        </div>
      </ClientOnly>
    </template>
  </TextField>
</template>

<script setup lang="ts">
import { EIconPath } from '../enums/iconPath.enum';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    placeholder?: string;
    disabled?: boolean;
  }>(),
  {
    modelValue: '',
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'search', value: string): void
  (e: 'clear'): void
}>();

const watchSubscription = new WatchSubscription();
const searchText = ref('');

function onClickSearch(): void {
  emit('search', searchText.value);
}

async function onClickClear(): Promise<void> {
  searchText.value = '';
  await nextTick();
  emit('search', searchText.value);
  emit('clear');
}

function emitUpdateModelValue(): void {
  emit('update:modelValue', searchText.value || '');
}

watchSubscription.add(
  watch(
    () => searchText.value,
    () => emitUpdateModelValue(),
  ),

  watch(
    () => props.modelValue,
    (newModelValue) => searchText.value = newModelValue || '',
    { immediate: true },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-search-text-field {
  height: 36px;
  width: 274px;

  :deep(.mm-input__input-container) {
    height: 36px;
    display: flex;

    input {
      height: 36px;
    }
  }

  &_after {
    &__container {
      position: absolute;
      right: 14px;
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 2;

      &--disabled {
        pointer-events: none;
      }

      .icon {
        cursor: pointer;

        &:hover {
          :deep(path) {
            fill: $link;
          }
        }
      }
    }
  }

  &__clear__icon {
    border-right: 1px solid $dark-gray;
    padding-right: 6px;
    margin-right: 8px;
    box-sizing: content-box;
  }

  &.text {
    :deep(input) {
      padding-right: 75px;
    }
  }
}
</style>
